// Import react library
import React from 'react';
// Import resources
import heart from './heart.svg';
import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={heart} className="App-logo" alt=""/>
        <p>
          Are you lost?
        </p>
      </header>
    </div>
  );
}

export default App;
